import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Button } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import Icon from 'antd/es/icon';
import { Row, Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { AppLandingHelper } from '@axmit/router-app-landing/index';
import { EClientRoutes } from 'const';
import SEO from 'components/Seo';
import { getTranslates } from 'helpers/translate.helper';

export default ({ location }) => {
  const data = useStaticQuery(query);
  const {
    i18n: { language }
  } = useTranslation();

  const isCompany = location?.state?.isCompany;
  const translateData = isCompany ? data.allStrapiLesLandBenefitsCorporate : data.allStrapiLesLandBenefitsPersonal;

  return (
    <Row className="mt-30">
      <SEO title="Benefits" />
      <Col className="hidden_mob" md={8} lg={6} xl={5}>
        <Img fixed={data.lockDesktop.childImageSharp.fixed} alt="Lock" />
      </Col>
      ​
      <Col xs={24} md={16} lg={12} xl={14} className="ta-c">
        <div className="benefits">
          <div className="benefits__header">
            <Typography.Title className="clp-typography-title clp-typography-title_size_md mb-5">
              {getTranslates(translateData, 'header', language)}
            </Typography.Title>
          </div>

          <div className="benefits__content">
            <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-20">
              {getTranslates(translateData, 'content1', language)}
            </Typography.Text>

            <Typography.Text className="clp-typography-text t_with-line-breaks clp-typography-text_size_md mb-20">
              {getTranslates(translateData, 'content2', language)}
            </Typography.Text>

            <Typography.Text className="clp-typography-text t_with-line-breaks clp-typography-text_size_md mb-20">
              {getTranslates(translateData, 'content3', language)}
            </Typography.Text>

            <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-25">
              {getTranslates(translateData, 'content4', language)}
            </Typography.Text>

            {isCompany && (
              <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-25">
                {getTranslates(translateData, 'content5', language)}
              </Typography.Text>
            )}

            <Button
              type="primary"
              className="ant-btn_mob-block mb-15"
              size="small"
              onClick={() =>
                AppLandingHelper.toggleRoute(isCompany ? EClientRoutes.SignUpCorporate : EClientRoutes.SignUpPersonal)
              }
            >
              <span>
                {getTranslates(translateData, 'btn__submit', language)}
                <Icon type="right" className="ml-20" />
              </span>
            </Button>

            <Img fixed={data.lockMobile.childImageSharp.fixed} alt="Lock" className="hidden_desk" />
          </div>
        </div>
      </Col>
    </Row>
  );
};

const query = graphql`
  query {
    lockDesktop: file(relativePath: { eq: "images/lockDesk.png" }) {
      childImageSharp {
        fixed(height: 590) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lockMobile: file(relativePath: { eq: "images/lockMob.png" }) {
      childImageSharp {
        fixed(height: 298) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allStrapiLesLandBenefitsCorporate {
      edges {
        node {
          header__en
          header__fr
          content1__en
          content1__fr
          content2__en
          content2__fr
          content3__en
          content3__fr
          content4__en
          content4__fr
          content5__en
          content5__fr
          btn__submit__en
          btn__submit__fr
        }
      }
    }
    allStrapiLesLandBenefitsPersonal {
      edges {
        node {
          header__en
          header__fr
          content1__en
          content1__fr
          content2__en
          content2__fr
          content3__en
          content3__fr
          content4__en
          content4__fr
          btn__submit__en
          btn__submit__fr
        }
      }
    }
  }
`;
